<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <h5 slot="header" class="mb-0">
              <b-button size="sm" :to="{ name: 'admin.loan-repayments-error-code.list' }" class="mr-2"><i
                  class="fa fa-chevron-left"></i> Буцах</b-button>
              <span>Засах:
                <strong class="text-primary">{{
                  form.payDescription
                }}</strong></span>
            </h5>
            <b-alert variant="info" :show="form.phoneNumber !== null && form.phoneNumber !== undefined">
              Хэрэглэгчийн утасны дугаар: <strong>{{ form.phoneNumber }}</strong>
              <b-badge variant="info" class="ml-2" style="font-size: 14px;" v-if="form.isClose">хаах</b-badge>
              <b-badge variant="info" class="ml-2" style="font-size: 14px;" v-else>эргэн төлөлт</b-badge>
            </b-alert>
            <b-form-group id="input-group-amount" label="Дүн:" label-for="input-amount">
              <money id="input-amount" class="form-control" v-model="form.amount" type="text" required placeholder="Дүн"
                readonly></money>
            </b-form-group>

            <b-form-group id="input-group-transactionDate" label="Төлсөн огноо:" label-for="input-transactionDate">
              <b-form-input v-model="form.transactionDate" placeholder="Төлсөн огноо" required readonly></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-payDescription" label="Гүйлгээний утга:" label-for="input-payDescription">
              <b-form-input id="input-payDescription" v-model="form.payDescription" required placeholder="Гүйлгээний утга"
                readonly></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-payAccount" label="Төлсөн данс:" label-for="input-payAccount">
              <b-form-select id="input-payAccount" v-model="form.payAccountNumber" placeholder="Төлсөн данс"
                :options="accountList"></b-form-select>
            </b-form-group>

            <div>
              <b-form-group id="input-group-payDescription" label="Утасны дугаар" label-for="input-username">
                <money id="input-username" class="form-control" :class="{
                  'border-danger': !selectCustomer,
                  'border-primary': selectCustomer,
                }" v-model="username" type="text" required placeholder="Утасны дугаар" suffix="" thousands=""
                  :precision="0"></money>
              </b-form-group>
            </div>
            <div v-if="selectCustomer">
              <b-alert show>
                <b-button :to="{
                  name: 'admin.customer.detail',
                  params: { id: selectCustomer.id },
                }" target="_blank" class="pull-right " size="sm" variant="info">Дэлгэрэнгүй</b-button>
                <h4 class="mb-0">{{ selectCustomer.lastname }} {{ selectCustomer.firstname }} /{{
                  selectCustomer.registerNumber }}/ </h4>
              </b-alert>

              <b-form-group id="input-group-3">
                <b-form-checkbox value="true" v-model="form.isClose" switch>Хаах эсэх</b-form-checkbox>
              </b-form-group>
              <div v-show="form.isClose && form.customer">
                <label for="">Идэвхтэй зээл</label>

                <b-table bordered small :fields="columns" :items="accounts">
                  <template v-slot:head(checkbox)>
                    <b-form-checkbox v-model="selectAll"></b-form-checkbox>
                  </template>
                  <template v-slot:cell(loanType)="data">
                    {{ $store.getters.loan_type[data.item.typeCode] }}
                  </template>
                  <template v-slot:cell(checkbox)="data">
                    <b-form-checkbox type="checkbox" :value="data.item" v-model="form.accounts"></b-form-checkbox>
                  </template>
                </b-table>
              </div>

              <b-form-group id="input-group-description" label="Эргэн төлөлтийн хуваарь:" label-for="input-description"
                class="mb-0" v-show="!form.isClose && form.customer">
                <div class="form-control mb-3">
                  <strong class="">{{ schedules.paymentDate }} </strong> -
                  <strong class="text-danger">{{ schedules.paymentAmount }}₮</strong>
                </div>
              </b-form-group>

            </div>
            <div v-else>
              <b-alert variant="warning" show>
                Хэрэглэгч байхгүй байна
              </b-alert>
            </div>

            <b-form-group id="input-group-description" label="Тайлбар:" label-for="input-description" class="mb-0">
              <b-form-input id="input-description" v-model="form.description" placeholder="Тайлбар"></b-form-input>
            </b-form-group>

            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3">Шинэчлэх</b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "LoanRepayments.new",
  components: { datePicker },
  data: function () {
    return {
      username: "",
      selectCustomer: null,
      isLoading: false,
      isLoadingCustomer: false,
      isLoadingAccounts: false,
      form: {
        amount: "",
        transactionDate: "",
        payDescription: "",
        payAccountNumber: "",
        isClose: false,
        customer: null,
        description: "",
        accounts: [],
      },
      show: true,
      customers: [],
      accounts: [],
      schedules: {
        paymentDate: "",
        paymentAmount: "0",
        fee: "0",
        amount: "0",
      },
      accountList: [],
      columns: [
        { label: "", key: "checkbox" },
        { label: "Зээлийн төрөл", key: "loanType" },
        { label: "Дансны дугаар", key: "accountNumber" },
        {
          label: "Авсан хэмжээ",
          key: "loanAmount",
          class: "text-right",
          sortable: true,
          sort_key: "loan_amount"
        },
        {
          label: "Үлдэгдэл",
          key: "loanBalance",
          class: "text-right",
          sortable: true,
          sort_key: "loan_balance"
        },
        {
          label: "Авсан огноо",
          key: "loanDate",
          sortable: true,
          sort_key: "loan_date"
        },
        {
          label: "Хаах огноо",
          key: "expiryDate",
          sortable: true,
          sort_key: "expiry_date"
        }
      ],
      selected: [],
      selectAll: false
    };
  },
  created: function () {
    this.checkSession();
    this.getAccountList();
    this.loadData();
  },
  watch: {
    "form.isClose": {
      handler(isClose) {
        if (isClose) {
          this.loadAccounts();
        } else {
          this.payRepayment();
        }
      },
      deep: true,
    },
    "username": {
      handler(username) {
        let _username = username.toString();
        this.$data.selectCustomer = null
        if (_username && (_username.length > 7 || _username.length < 10)) {
          this.getCustomerShort(_username);
        }
      },
    },
    selectAll: {
      handler(selectAll) {
        this.$data.form.accounts = [];
        if (this.selectAll) {
          this.$data.form.accounts = this.$data.accounts
        }
      },
      deep: true
    },
    'form.accounts': {
      handler(accounts) {
        if (!this.selectAll) {
          if (this.$data.form.accounts.length == this.$data.accounts.length && this.$data.form.accounts.length > 0) {
            this.selectAll = true
          }
        }
      },
      deep: true
    },
  },
  methods: {
    getCustomerShort: function (phone) {
      this.isLoading = true;
      this.$data.selectCustomer = null;
      this.$http
        .get(
          this.$config.API_URL +
          "CustomerWebService/get_customer_short_info_by_register",
          {
            params: {
              phone: phone,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.data) {
              this.$data.selectCustomer = response.data;
              this.$data.form.customer = this.$data.selectCustomer
              this.$data.accounts = []
              this.$data.form.isClose = false
              this.payRepayment();
            } else {
              this.showToast(
                "Анхаар",
                phone + " утасны дугаартай хэрэгдэгчийн мэдээлэл байхгүй байна",
                "warning"
              );
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    loadAccounts: function () {
      if (this.$data.form.isClose && this.$data.form.customer) {
        this.accounts = [];
        this.isLoadingAccounts = true;
        this.$http
          .get(
            this.$config.API_URL + "CustomerWebService/get_active_loan_list_short",
            {
              params: {
                id: this.$data.form.customer.id
              },
              headers: this.$store.getters.httpHeader,
            }
          )
          .then(
            (response) => {
              this.isLoadingAccounts = false;
              this.$data.accounts = response.body.data;
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    loadData: function () {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/get_error_repayment",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              id: this.$route.params.id,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.form = {
              id: response.body.id,
              amount: response.body.amount,
              transactionDate: response.body.transactionDate,
              payDescription: response.body.payDescription,
              payAccountNumber: response.body.payAccountNumber,
              isClose: false,
              customer: null,
              description: response.body.description,
              accounts: [],
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getAccountList: function () {
      this.$http
        .get(this.$config.API_URL + "LoanWebService/get_bank_account_list", {
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoadingAccounts = false;
            this.$data.accountList = response.body.data;
          },
          (response) => {
            this.isLoadingAccounts = false;
          }
        );
    },
    payRepayment: function () {
      if (!this.$data.form.isClose && this.$data.form.customer) {
        this.isLoadingAccounts = true;
        this.$http
          .get(
            this.$config.API_URL + "CustomerWebService/get_repayment_schedule",
            {
              params: {
                id: this.$data.form.customer.id,
              },
              headers: this.$store.getters.httpHeader,
            }
          )
          .then(
            (response) => {
              this.isLoadingAccounts = false;
              //console.log(response)
              if (response.body.data.length > 0) {
                this.$data.schedules.paymentDate =
                  response.body.data[0].paymentDate;
                this.$data.schedules.paymentAmount =
                  response.body.data[0].paymentAmount;
                this.$data.schedules.fee = response.body.data[0].fee;
                this.$data.schedules.amount = response.body.data[0].amount;
              }
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    onSubmit(evt) {
      if (this.$data.form.customer != null) {
        this.isLoading = true;
        this.$data.form.token = this.$store.getters.token;
        this.$data.form.email = this.$store.getters.email;
        this.$data.form.userId = this.$store.getters.user.id;
        this.$data.form.isCreate = false;
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/update_error_repayment",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                // this.back()
              } else {
                this.showToast("Алдаа", "Хадгалах үед алдаа гарлаа", "danger");
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
      } else {
        this.showToast("Алдаа", "Хэрэглэгч сонгоогүй байна", "warning");
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.$data.form.customer = null;
      this.$data.form.isClose = false;
      this.$data.form.description = "";
      this.$data.form.accounts = [];
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    customerName({ lastname, firstname, register, phone }) {
      return `${lastname} — ${firstname} — ${register} — ${phone}`;
    },
  },
};
</script>
